import { runningData, i18n } from '@/main';

export class LocaleManager {
    static currentLocale = '';

    static async setGlobalLocale(localeString){
        // Try exact matches first
        for (const item of runningData.languageInfo){
            if ((item as any).babelId == localeString){
                console.log("Exact matched!")
                const selectedLanguageBabel = (item as any).babelId;

                if (selectedLanguageBabel == LocaleManager.currentLocale){return;}

                try {
                    const messages = await import(`../languages/${selectedLanguageBabel}.json`)
                    await i18n.global.setLocaleMessage(selectedLanguageBabel, messages.default)
                    i18n.global.locale = selectedLanguageBabel
                    return
                }catch (error){ console.log("Could not import language (1)!"); }
            }
        }

        // Try just the language
        for (const item of runningData.languageInfo){
            if ((item as any).babelId.split('-')[0] == localeString.split('-')[0]){
                console.log("Partial matched!")
                const selectedLanguageBabel = (item as any).babelId;

                if (selectedLanguageBabel == LocaleManager.currentLocale){return;}

                try {
                    const messages = await import(`../languages/${selectedLanguageBabel}.json`)
                    await i18n.global.setLocaleMessage(selectedLanguageBabel, messages.default)
                    i18n.global.locale = selectedLanguageBabel
                    return
                }catch (error){ console.log("Could not import language (2)!"); }
            }
        }

        // Fallback to English
        const country = localeString.split('-')[1]
        const messages = await import('../languages/en-US.json')
        await i18n.global.setLocaleMessage('en-US', messages.default)
        i18n.global.locale = `en-${country}`
    }
}