import { createApp } from 'vue'
import App from './AppIonic.vue'
//import App from './AppVue.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Bootstrap */
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import "bootstrap"

/* Fixes for Vie theme */
import "@/assets/css/overrides.css"

/* Axios */
import axios from 'axios';

/* Particles UI effect */
import Particles from "vue3-particles";

/* Preferences */
//import { Storage } from '@ionic/storage';
// import { StorageKey } from '@/internal/storage';
// const storage = new Storage();
// await storage.create();

// /* Font Awesome */
// import { library, dom } from "@fortawesome/fontawesome-svg-core"

// /* import font awesome icon component */
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
// library.add(fas, far, fab)
// dom.watch()

/* i18n */
import { createI18n } from 'vue-i18n'
import { LocaleManager } from './internal/locale';

export const i18n = createI18n({
  locale: 'en-US'
})

export const { t } = i18n.global

const app = createApp(App)
  //.component("font-awesome-icon", FontAwesomeIcon)
  .use(i18n)
  .use(IonicVue)
  .use(Particles)
  .use(router);


// /* Sentry */
// import * as Sentry from "@sentry/vue";

// Sentry.init({
//   app,
//   dsn: "https://90c7fcf2bd8b4ea79722c6fa2329a154@o4505494014918656.ingest.sentry.io/4505494018719744",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["com-badge.com"],  // , /^https:\/\/yourserver\.io\/api/
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

export const runningData = {
  presentationLang: null,
  languageInfo: (await import('./languages/languageInfo.json')).objects,
  countryInfo: (await import('./languages/countries.json')).objects,
  pathCounty:'',
  pathLanguage: '',
}

// app.config.globalProperties.$serverBaseURL = 'http://192.168.16.41:8080'

export const axiosInstance = axios.create({
  baseURL: app.config.globalProperties.$serverBaseURL,
  validateStatus: undefined,
  timeout: 1000 * 15,
});

// Sentry crash reporting

router.isReady().then(async () => {
  const pathLang = router.currentRoute.value.fullPath.split('/')[1]
  const language = pathLang.split('-')[0]
  const country = pathLang.split('-')[1]

  await LocaleManager.setGlobalLocale(pathLang);

  // Load the current local's messages (individual views do this)
  // const value = await storage.get(StorageKey.PRESENTATION_LANG)
  // if (value != null){ 
  //   runningData.presentationLang = JSON.parse(value).value
  //   const messages = await import(`./languages/${runningData.presentationLang}.json`)
  //   i18n.global.setLocaleMessage(runningData.presentationLang as any, messages.default)
  //   i18n.global.locale = runningData.presentationLang as any
  // } else {
  //   try { // Try to use the device lang
  //     const messages = await import(`./languages/${window.navigator.language}.json`)
  //     i18n.global.setLocaleMessage(window.navigator.language, messages.default)
  //     i18n.global.locale = window.navigator.language as any
  //     (runningData.presentationLang as any) = window.navigator.language
  //   }catch (error) { // Default to English
  //     const messages = await import('./languages/en-US.json')
  //     i18n.global.setLocaleMessage('en-US', messages.default)
  //     i18n.global.locale = 'en-US';
  //     (runningData.presentationLang as any) = 'en-US'
  //   }
  // }

  app.mount('#app');
});