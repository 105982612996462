// import TabsPage from '../views/TabsPage.vue'
// import LoginPage from '../views/LoginPage.vue'
// import RegisterPage from '../views/RegisterPage.vue'
// import ForgotPasswordPage from '../views/ForgotPasswordPage.vue'
// import { i18n } from '@/main'
const HomeView = () => import("../views/HomePage.vue")
const NullPage = () => import("../views/NullPage.vue")
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import languageInfo from '../languages/languageInfo.json'
import countryInfo from '../languages/countries.json'
import { nextTick } from 'vue'
import { runningData } from '@/main'
//import { createRouter, createWebHistory } from 'vue-router';

// import { gtag } from '@types/gtag.js'

const routes: Array<RouteRecordRaw> = [ 
  {
    path: '/',
    beforeEnter: async (to, from) => {
      let countryCode = null
      try {
        const headers = new Headers();
        const response = await fetch("https://geoip-js.com/geoip/v2.1/country/me?pretty");

        countryCode = (await response.json()).country.iso_code

        if (countryCode){
          console.log("--> IP country: ", countryCode);
          console.log("--> Browser locale", navigator.language);

          // Try to find a matching locale, based on the browser language only
          let browserLang = null
          if (navigator != undefined && navigator.language != undefined){
            if (navigator.language.indexOf('-') != -1){ (browserLang as any) = navigator.language.split('-')[0] }
            else { (browserLang as any) = navigator.language }
          }

          // Try exact matches first
          for (const item of languageInfo.objects){
            if ((item as any).babelId == browserLang + '-' + countryCode){
              console.log("Exact match")
              const selectedLanguageBabel = (item as any).babelId;

              return `/${selectedLanguageBabel}`

              // try {
              //   const messages = await import(`../languages/${selectedLanguageBabel}.json`)
              // }catch (error){ console.log("Could not import language!"); }
            }
          }

          // Return the country's default language, if we have one
          for (const countryItem of countryInfo.objects){
            if (countryItem.code == countryCode){
              for (const languageItem of languageInfo.objects){
                if ((languageItem as any).babelId.split('-')[0] == countryItem.language.code){
                  console.log("Country language match")
                  return `${countryItem.language.code}-${countryCode}`
                }
              }
            }
          }

          // Try the browser's language
          for (const item of countryInfo.objects){
            if (item.code == countryCode){
              if (item.language.code == browserLang){
                console.log("Browser language match")
                return `/${browserLang}-${countryCode}`
              }
            }
          }

        }
      } catch(error) {console.log(error)} 

      return '/en-US'

    },
    component: () => { return NullPage }
  },
  {
    path: '/:currentLocale',
    name: 'HomeView',
    meta: {'title': 'Ankh Innovations LLC'},
    component: HomeView,
  },

  // {path: '/login', component: LoginPage},
  // {path: '/register', component: RegisterPage},
  // {path: '/forgot-password', component: ForgotPasswordPage},

  // {
  //   path: '/tabs/',
  //   component: TabsPage,
  //   children: [
  //     {path: '', redirect: '/tabs/tab1'},
  //     {path: 'tab1', component: () => import('@/views/Tab1Page.vue')},
  //     {path: 'tab2', component: () => import('@/views/Tab2Page.vue')},
  //     {path: 'tab3', component: () => import('@/views/Tab3Page.vue')}
  //   ]
  // }
]

// For ionic
// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const DEFAULT_TITLE = 'Some Default Title';
router.afterEach((to, from) => {
    nextTick(() => {
      const pathLang = to.path.split('/')[1]
      const language = pathLang.split('-')[0]
      const country = pathLang.split('-')[1]
      let countryName = ''

      for (const item of countryInfo.objects){
        if ((item as any).code == country){
            countryName = item.name
        }
      }

      runningData.pathCounty = country
      runningData.pathLanguage = language

      document.title = (to.meta.title as string) + ' - ' + countryName || DEFAULT_TITLE;

      // Send Google Analyitcs
      // (window as any).gtag('set', 'page_path', to.fullPath);
      // (window as any).gtag('event', 'page_view');
    });
});

export default router
